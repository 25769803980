import SectionWrapper from "../../../sections/SectionWrapper"
import { queries } from "../../../../breakpoints"
import styled from 'styled-components';

const IMAGE_HEIGHT = "150px"
const IMAGE_WIDTH = "250px"

export const Section = styled(SectionWrapper)``;

export const Headline = styled.h2`
    font-weight: bold;
    text-align: center;
`;

export const Container = styled.div`
    margin-top: 30px;
    display: flex;
    @media(${queries.max_break_md}){
        flex-flow: column;
    }
`;

export const StyledStep = styled.article`
    flex: 1;
    text-align: center;
`;

export const Image = styled.img`
    width: ${IMAGE_WIDTH};

    @media(${queries.max_break_lg}){
        width:100%;
        height:unset;
        padding:10px;
    }
    @media(${queries.max_break_md}){
        width: ${IMAGE_WIDTH};
    }
`;

export const Content = styled.div`
    max-width: ${IMAGE_WIDTH};
    margin: 0 auto;
`;

export const Number = styled.h6`
    color: #AEAEAE;
    text-transform: uppercase;
    letter-spacing: 1.31px;
    margin-top: 24px;
    font-weight: bold;
`;

export const StepHeadline = styled.h5`
    font-weight: bold;
`;

export const StepSubheadline = styled.p``;

export const StyledArrow = styled.div`
    height: ${IMAGE_HEIGHT};
    display: flex;
    align-items: center;
    @media(${queries.max_break_md}){
        display:none;
    }
`;