import { Container, Content, Headline, Image, Number, Section, StepHeadline, StepSubheadline, StyledArrow, StyledStep } from "./StepsSection.styles"

import PropTypes from 'prop-types';
import React from 'react';

const StepsSection = (props) => (
  <Section className="steps-section" backgroundColor={props.backgroundColor}>
    { props.headline && <Headline>{props.headline}</Headline> }
    <Container>
      {props.steps.map((step, i) => (
        <React.Fragment key={i}>
          <Step 
            key={i} 
            number={i + 1}
            headline={step.headline} 
            imageSrc={step.imageSrc} 
            description={step.description}
          />
          { i !== props.steps.length-1 && <Arrow key={`arrow-${i}`}/>}
        </React.Fragment>
      ))}
    </Container>
  </Section>
);

StepsSection.propTypes = {
  headline: PropTypes.string,
  backgroundColor: PropTypes.string,
  steps: PropTypes.arrayOf(PropTypes.shape({
    headline: PropTypes.string,
    description: PropTypes.string,
    imageSrc: PropTypes.string
  }))
};

StepsSection.defaultProps = {
  headline: "",
  backgroundColor: null,
  steps: []
};

export default StepsSection;

const Step = ({imageSrc, headline, description, number}) => {
  return(
    <StyledStep>
      <Image src={imageSrc} alt={`${headline}`}/>
      <Content>
        <Number>Step {number}</Number>
        <StepHeadline>{headline}</StepHeadline>
        <StepSubheadline>{description}</StepSubheadline>
      </Content>
    </StyledStep>
  )
}

const Arrow = () => {
  return(
    <StyledArrow>
      <img
        src="https://res.cloudinary.com/grubhub-marketing/image/upload/v1628623487/Grubhub%20Corporate%20Website/2021/Navigation/RightArrows.svg"
        alt=""
      />
    </StyledArrow>
  )
}