import CtaSection from "../components/sections/CtaSection"
import FaqAccordionSection from "../components/sections/FaqAccordionSection"
import IllustrationHeader from "../components/global/IllustrationHeader"
import Layout from "../components/global/Layout"
import QuoteSection from "../components/sections/QuoteSection"
import React from "react"
import StepsSection from "../components/page-specific/How-Grubhub-Works/StepsSection"

const HowItWorks = ({ data, location }) => {

  const faqs = [
    {
      header: "What is a Grubhub Corporate Account?",
      description: "A Grubhub Corporate Account connects you and your teams with delicious meals from your favorite local restaurants. With group ordering, catering and corporate-friendly features like budgets, rules and consolidated billing\u2014feeding the office has never been easier."
    },
    {
      header: "How does Grubhub work for businesses?",
      description: "A Grubhub Corporate Account makes it easy for businesses to order meals for employees, business meetings, clients and more. By offering a line of credit, you will only pay for meals that are actually ordered helping cut costs. "
    }
  ]

  const steps = [
    {
      headline: "Sign up",
      description: "Create a Self-Service account to order today, or talk to our Sales team to build your company’s custom meal perk solution.",
      imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/v1652231547/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/May%2011%20Handoff%20-%20NO%20HOUSE%20UPDATE/How%20It%20Works/EXPORT-HowItWorks-Step1.png"
    },
    {
      headline: "Set rules",
      description: "Customize your budget, delivery dates, locations, and other restrictions.",
      imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/v1652231547/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/May%2011%20Handoff%20-%20NO%20HOUSE%20UPDATE/How%20It%20Works/EXPORT-HowItWorks-Step2.png"
    },
    {
      headline: "Enjoy delicious food",
      description: "Let your team start ordering from hundreds of thousands of restaurants nationwide, and only pay for the amount of credit used.",
      imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/v1632933620/Grubhub%20Corporate%20Website/2021%20%E2%80%94%20Citrus/Illustrations/B-HowItWorks/EXPORTS-Illustrations-HowItWorks-Step3-Food-Citrus.png"
    }
  ]

  return (
    <Layout
      location={location}
      title={"How it Works | Grubhub Corporate"}
      description={"With a Grubhub Corporate Account, it's simple to order meals whenever you need. Here's how it works."}
      utag_data={{
        pageGroup: "grubhub - lead generation",
        subGroup: "learn more",
        pageName: "how it works",
        brand: "grubhub"
      }}
      faqSeoList={faqs}
    >
      <IllustrationHeader 
        headline="How it works" 
        subheadline="Build the perfect meal perk program that satisfies every appetite." 
        ctaLink="/get-started/" 
        background="radial-gradient(circle, #612889 0%, var(--color-primary-darker) 100%)"
        leftSideIllustration="https://res.cloudinary.com/grubhub-marketing/image/upload/v1628277587/Grubhub%20Corporate%20Website/2021/Header%20Images/Illustrated%20Headers%20/6-How%20It%20Works%20%28Same%20as%20Reasons%20To%20Order%29/DESKTOP-HowitWorks-LEFT_IMAGE.png"
        altLeftSide="Illustration of a pizza with two pieces of bacon and a fried egg flying over it."
        rightSideIllustration="https://res.cloudinary.com/grubhub-marketing/image/upload/v1652231547/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/May%2011%20Handoff%20-%20NO%20HOUSE%20UPDATE/How%20It%20Works/DESKTOP-HowitWorks-FeedEveryOccassion-RIGHT_IMAGE.png"
        altRightSide="Illustration of an orange Grubhub food delivery bag."
        mobileIllustration="https://res.cloudinary.com/grubhub-marketing/image/upload/v1652367970/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/May%2011%20Handoff%20-%20NO%20HOUSE%20UPDATE/How%20It%20Works/MOBILE-HowItWorks-HeaderImage.png"
        altMobile="Illustration of an orange Grubhub food delivery bag."
      />
      <StepsSection headline="Treat your team to delicious food" steps={steps} />
      <QuoteSection
        author="Sujeidy Palmer"
        position="Managing Director, Teacher Preparation Program, Teach For America"
        imageSrc="https://res.cloudinary.com/grubhub-marketing/image/upload/v1649347928/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/March%208%20Handoff/How%20It%20Works/PARTNERLOGOS-TeachForAmerica.png"
        alt="Teach for America logo"
        backgroundColor="#f6f6f6"
        quote='"Our account was so easy to set up and use! It has made such a big difference, allowing us to connect and break bread with each other, as we engage in this hybrid world!"'
      />
      <CtaSection headline="Discover the industry leader in flexible meal benefits" />
      <FaqAccordionSection faqs={faqs}   backgroundColor="#f6f6f6" />
    </Layout>
  )
}

export default HowItWorks
